
import {
    defineComponent, ref, Ref
} from 'vue';
import { account, accountType } from '@/data';
import { format } from '@/methods/date';
import HttpRequest from '@/util/axios.config';
import { statusOptions, typeOptions } from './util';

export default defineComponent({
    setup() {
        const beginTime: Ref<'' | Date> = ref('');
        const endTime: Ref<'' | Date> = ref('');
        const status = ref('all');
        const manageID = ref('all');
        const installID = ref('all');
        const type = ref('all');

        const disOptions: Ref<accountType.GetAllDisInstaller> = ref([]);
        const disInstallerOptions: Ref<Array<{ ID: string; Account: string }>> = ref([]);
        account.getAllDisInstaller((data: accountType.GetAllDisInstaller) => {
            disOptions.value = data;
        });
        const changeDis = (ID: string) => {
            installID.value = 'all';
            disOptions.value.forEach((item) => {
                console.log(item.ID === ID);
                if (item.ID === ID) disInstallerOptions.value = item.Children;
            });
        };

        const exportFile = () => {
            const element = document.createElement('a');
            element.setAttribute('target', '_blank');
            element.setAttribute('download', '');
            element.setAttribute('href', `${HttpRequest.defaultUrl}exportorder?token=${localStorage.getItem('token')}&BeginTime=${
                format(beginTime.value)}&EndTime=${format(endTime.value)}&Status=${status.value}&ManageID=${manageID.value}&InstallID=${
                installID.value}&Type=${type.value}&t=${Math.round(new Date().getTime() / 1000).toString()}`);
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        };

        return {
            beginTime,
            endTime,
            status,
            manageID,
            installID,
            type,
            statusOptions,
            typeOptions,
            disOptions,
            disInstallerOptions,
            changeDis,
            exportFile
        };
    }
});
